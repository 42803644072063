import { VSpacing } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import { H2Section } from 'bloko/blocks/header';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ArticleListItemMedium from 'src/components/ArticleItem/ArticleListItemMedium';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import styles from './read-more.less';

const TrlKeys = {
    title: 'article.readMore',
};

const ReadMore: TranslatedComponent = ({ trls }) => {
    const readMoreArticles = useSelector(({ articlePage }) => articlePage.readMore);

    if (!readMoreArticles?.length) {
        return <></>;
    }

    return (
        <div className={styles.readMore}>
            <H2Section>{trls[TrlKeys.title]}</H2Section>
            <VSpacing xs={24} default={20} />
            <div className={styles.list}>
                {readMoreArticles.map((item) => (
                    <div key={item.code} className={styles.itemContainer}>
                        <Column xs="4" s="4" m="4" l="4">
                            <ArticleListItemMedium item={item} />
                        </Column>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default translation(ReadMore);
