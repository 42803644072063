import { FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import VSeparator from 'src/components/VSeparator';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import SuitableVacancies from 'src/pages/Article/components/Sidebar/DefaultSidebar/SuitableVacancies';
import VacanciesOfTheDay from 'src/pages/Article/components/Sidebar/DefaultSidebar/VacanciesOfTheDay';

import styles from 'src/pages/Article/components/Sidebar/sidebar.less';

const ArtilceSidebarDefault: FC = () => {
    const suitableVacanciesCollection = useSelector((state) => state.suitableVacancies?.vacancies);
    const vacanciesOfTheDayCollection = useSelector((state) => state.vacanciesOfTheDay?.vacancies);

    const isZP = useIsZarplataPlatform();

    const showSuitableVacancies = suitableVacanciesCollection && suitableVacanciesCollection.length > 0;
    const showVacanciesOfTheDay = vacanciesOfTheDayCollection?.length > 0;
    const showArticleSidebar = showSuitableVacancies || showVacanciesOfTheDay;

    if (isZP || !showArticleSidebar) {
        return null;
    }

    return (
        <div className={styles.sidebar}>
            {showSuitableVacancies && <SuitableVacancies collection={suitableVacanciesCollection} />}
            {showSuitableVacancies && showVacanciesOfTheDay && (
                <>
                    <VSpacing default={24} />
                    <VSeparator />
                    <VSpacing default={16} />
                </>
            )}
            {showVacanciesOfTheDay && <VacanciesOfTheDay collection={vacanciesOfTheDayCollection} />}
        </div>
    );
};

export default ArtilceSidebarDefault;
