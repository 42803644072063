import React from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { H2 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import LandingTextContainer from 'src/pages/Article/components/common/LandingTextContainer';

import OtherInterview from 'src/pages/Article/components/Footer/InsiderLandingFooter/OtherInterview';

import styles from './other-interviews.less';

const TrlKeys = {
    companyVacancies: 'article.insiderInterview.vacancies',
    otherInterviews: 'article.insiderInterview.otherInterviews',
};

const InsiderLandingFooter: TranslatedComponent = ({ trls }) => {
    const info = useSelector((state) => state.articleEmployerInfo);
    const otherInterviews = useSelector((state) => state.insiderArticleSpecificData?.otherInterviews);

    if (!info) {
        return null;
    }

    return (
        <LandingTextContainer>
            <BlokoLink Element={Link} to={`/employer/${info.id}`} disableVisited>
                {trls[TrlKeys.companyVacancies]}
            </BlokoLink>
            {otherInterviews && otherInterviews.length > 0 && (
                <>
                    <VSpacing default={32} xs={20} />
                    <div className={styles.interviewsBlock} id="interview">
                        <div className={styles.mobileBorderBottom}>
                            <VSpacing default={32} xs={20} />
                            <H2 Element="h3">{trls[TrlKeys.otherInterviews]}</H2>
                            <VSpacing default={20} xs={12} />
                        </div>
                        <div className={styles.interviewsList}>
                            {otherInterviews.map((interview) => (
                                <OtherInterview interview={interview} employerId={info.id} key={interview.code} />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </LandingTextContainer>
    );
};

export default translation(InsiderLandingFooter);
