import React, { FC } from 'react';

import EmployerInfoSidebar from 'src/pages/Article/components/Sidebar/InsiderSimpleSidebar/InsiderEmployerInfo';
import InsiderOtherInterviews from 'src/pages/Article/components/Sidebar/InsiderSimpleSidebar/InsiderOtherInterviews';
import InsiderParticipantsBlock from 'src/pages/Article/components/Sidebar/InsiderSimpleSidebar/InsiderParticipants';

import styles from 'src/pages/Article/components/Sidebar/sidebar.less';

const InsiderSimpleSidebar: FC = () => {
    return (
        <div className={styles.sidebar}>
            <EmployerInfoSidebar />
            <InsiderParticipantsBlock />
            <InsiderOtherInterviews />
        </div>
    );
};

export default InsiderSimpleSidebar;
