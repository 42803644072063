import React, { FC } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { ImageType } from 'src/models/articlePage';
import ArticleLead from 'src/pages/Article/components/ContentHeader/common/ArticleLead';
import MarginSpacing from 'src/pages/Article/components/common/MarginSpacing';

import LeadWithAuthor from 'src/pages/Article/components/ContentHeader/DefaultContentHeader/LeadWithAuthor/LeadWithAuthor';

const ContentHeaderDefault: FC = () => {
    const { authorPhotos, lead } = useSelector(({ articlePage }) => articlePage);
    const authorPhoto = authorPhotos?.find(({ type }) => type === ImageType.Related);

    if (!lead) {
        return null;
    }

    return (
        <>
            {authorPhoto ? <LeadWithAuthor photo={authorPhoto} /> : <ArticleLead />}
            <MarginSpacing bottom />
        </>
    );
};

export default ContentHeaderDefault;
