import React, { FC } from 'react';

import useArticleLead from 'src/pages/Article/components/ContentHeader/common/ArticleLead/useArticleLead';

import styles from './article-lead.less';

interface ArticleLeadProps {
    small?: boolean;
}

const ArticleLead: FC<ArticleLeadProps> = ({ small = false }) => {
    const content = useArticleLead();

    if (!content) {
        return null;
    }

    return <div className={small ? styles.leadSmall : styles.lead} dangerouslySetInnerHTML={{ __html: content }} />;
};

export default ArticleLead;
