import React, { FC, ReactNode, useRef } from 'react';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import ContainerForXSL from 'src/components/CodeInjector/ContainerForXSL';
import RowContent from 'src/components/RowContent';
import { useSelector } from 'src/hooks/useSelector';
import MarginSpacing from 'src/pages/Article/components/common/MarginSpacing';
import useScrollAnalytics from 'src/pages/Article/hooks/useScrollAnalytics';

import styles from './style.less';

interface BlogArticleLayoutProps {
    pageHeader: ReactNode;
    contentHeader: ReactNode;
    contentFooter: ReactNode;
    sidebar: ReactNode;
    smallContentSpacing?: boolean;
    spacingBeforeContent?: boolean;
}

const BlogArticleLayout: FC<BlogArticleLayoutProps> = ({
    pageHeader,
    contentHeader,
    contentFooter,
    sidebar,
    smallContentSpacing,
    spacingBeforeContent = true,
}) => {
    const { id } = useSelector(({ articlePage }) => articlePage);
    const articleEndRef = useRef<HTMLDivElement | null>(null);

    useScrollAnalytics(id, articleEndRef);

    if (sidebar) {
        return (
            <>
                {!!pageHeader && pageHeader}
                <ColumnsWrapper>
                    <RowContent>
                        <Column xs="4" s="8" m="12" l="16" container>
                            <Column xs="4" s="8" m="9" l="10">
                                {contentHeader}
                                {spacingBeforeContent && <MarginSpacing bottom />}
                                <ContainerForXSL place="legacy-page-layout-xsl" />
                                <div ref={articleEndRef} />
                                <MarginSpacing small={!!smallContentSpacing} />
                                {contentFooter}
                            </Column>
                            <Column xs="0" s="0" m="0" l="1" />
                            <Column xs="4" s="8" m="3" l="4">
                                {sidebar}
                            </Column>
                        </Column>
                    </RowContent>
                </ColumnsWrapper>
            </>
        );
    }

    return (
        <>
            {!!pageHeader && pageHeader}
            <RowContent>
                <div className={styles.landingContent}>
                    {contentHeader}
                    {spacingBeforeContent && <MarginSpacing bottom />}
                    <ContainerForXSL place="legacy-page-layout-xsl" />
                    <div ref={articleEndRef} />
                    <MarginSpacing small={!!smallContentSpacing} />
                    {contentFooter}
                </div>
            </RowContent>
        </>
    );
};

export default BlogArticleLayout;
