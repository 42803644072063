import React, { FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import { useSelector } from 'src/hooks/useSelector';

import Rating from 'src/pages/Article/components/Footer/DefaultFooter/Rating';
import ReadMore from 'src/pages/Article/components/Footer/DefaultFooter/ReadMore';

const FooterDefault: FC = () => {
    const articleRating = useSelector((state) => state.articleRating);
    const { id } = useSelector(({ articlePage }) => articlePage);

    return (
        <>
            {articleRating && (
                <>
                    <Rating articleId={id} voteState={articleRating.voteState} likesCount={articleRating.likesCount} />
                    <VSpacing default={44} xs={32} />
                </>
            )}
            <ReadMore />
        </>
    );
};

export default FooterDefault;
