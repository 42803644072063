import { FC } from 'react';

import ContainerForXSL from 'src/components/CodeInjector/ContainerForXSL';
import Portal from 'src/components/CodeInjector/Portal';
import { useSelector } from 'src/hooks/useSelector';

import AreaSwitcherPromo from 'src/pages/Article/components/Content/PromoArticle/AreaSwitcherPromo';

const PromoArticle: FC = () => {
    const showArticleAreaSwitcher = useSelector((state) => state.showArticleAreaSwitcher);

    return (
        <>
            <ContainerForXSL place="legacy-page-layout-xsl" />
            {showArticleAreaSwitcher && (
                <Portal place="promoAreaSwitcher">
                    <AreaSwitcherPromo />
                </Portal>
            )}
        </>
    );
};

export default PromoArticle;
