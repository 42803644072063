import { FC } from 'react';
import classNames from 'classnames';

import styles from './margin-spacing.less';

interface MarginSpacingProps {
    small?: boolean;
    bottom?: boolean;
}

// Because of margin-collapsing, it's important to use margins and not VSpacing before and after article body
const MarginSpacing: FC<MarginSpacingProps> = ({ small = false, bottom = false }) => (
    <div
        className={classNames({
            [styles.bottomMargin]: bottom,
            [styles.topMargin]: !bottom && !small,
            [styles.topMarginSmall]: !bottom && small,
        })}
    />
);

export default MarginSpacing;
