import { useSelector } from 'src/hooks/useSelector';
import { ArticleTypes } from 'src/models/articlePage/types';

const useArticleLead: () => string | null = () => {
    const { lead, announce, type } = useSelector(({ articlePage }) => articlePage);

    if (type === ArticleTypes.PressRelease) {
        return announce || null;
    }

    return lead || null;
};

export default useArticleLead;
