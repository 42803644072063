import React, { FC } from 'react';

import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { InsiderDesignType } from 'src/models/articlePage';
import { ArticleTypes } from 'src/models/articlePage/types';
import ContentHeaderDefault from 'src/pages/Article/components/ContentHeader/DefaultContentHeader';
import InsiderSimpleHeader from 'src/pages/Article/components/ContentHeader/InsiderSimpleHeader';
import LandingContentHeader from 'src/pages/Article/components/ContentHeader/LandingContentHeader';
import FooterDefault from 'src/pages/Article/components/Footer/DefaultFooter';
import InsiderLandingFooter from 'src/pages/Article/components/Footer/InsiderLandingFooter';
import PressReleaseFooter from 'src/pages/Article/components/Footer/PressReleaseFooter';
import PageHeaderDefault from 'src/pages/Article/components/PageHeader/DefaultPageHeader';
import InsiderLandingPageHeader from 'src/pages/Article/components/PageHeader/InsiderLandingPageHeader';
import SidebarDefault from 'src/pages/Article/components/Sidebar/DefaultSidebar';
import InsiderSimpleSidebar from 'src/pages/Article/components/Sidebar/InsiderSimpleSidebar';
import useIsInsider from 'src/pages/Article/hooks/useIsInsider';

import BlogArticle from 'src/pages/Article/components/Content/BlogArticle';
import PromoArticle from 'src/pages/Article/components/Content/PromoArticle';

const Content: FC = () => {
    const isInsider = useIsInsider();
    const { type, insiderDesignType } = useSelectorNonNullable((state) => state.articlePage);

    if (type === ArticleTypes.Promo) {
        return <PromoArticle />;
    }

    if (type === ArticleTypes.PressRelease) {
        return (
            <BlogArticle
                pageHeader={<PageHeaderDefault />}
                contentHeader={<LandingContentHeader />}
                contentFooter={<PressReleaseFooter />}
                sidebar={null}
                smallContentSpacing
            />
        );
    }

    if (isInsider && insiderDesignType === InsiderDesignType.Landing) {
        return (
            <BlogArticle
                pageHeader={<InsiderLandingPageHeader />}
                contentHeader={<LandingContentHeader />}
                contentFooter={<InsiderLandingFooter />}
                sidebar={null}
                smallContentSpacing
            />
        );
    }

    if (isInsider && insiderDesignType === InsiderDesignType.Simple) {
        return (
            <BlogArticle
                pageHeader={null}
                contentHeader={<InsiderSimpleHeader />}
                contentFooter={null}
                sidebar={<InsiderSimpleSidebar />}
            />
        );
    }

    return (
        <BlogArticle
            pageHeader={<PageHeaderDefault />}
            contentHeader={<ContentHeaderDefault />}
            contentFooter={<FooterDefault />}
            sidebar={<SidebarDefault />}
            spacingBeforeContent={false}
        />
    );
};

export default Content;
