import { FC, useCallback, useRef, useEffect } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import NoIndex from 'src/components/NoIndex';
import { OverlayTypes } from 'src/components/SupernovaOverlayMenu/Overlay';
import OverlayContent from 'src/components/SupernovaOverlayMenu/OverlayContent';
import useOverlayMenu from 'src/components/SupernovaOverlayMenu/useOverlayMenu';
import AreaSwitcherModal from 'src/components/SupernovaSearch/AreaSwitcherModal';
import useHandleOpenGeoSwitcher from 'src/hooks/useHandleOpenGeoSwitcher';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';

const BLOKO_COLOR_GREY_80 = '#303233';

const AreaSwitcherPromo: FC = () => {
    const domainAreaTitle = useSelector(({ domainAreaTitle }) => domainAreaTitle);
    const isXs = useBreakpoint() === Breakpoint.XS;
    const [areaSwitchVisible, setAreaSwitchVisible, setAreaSwitchHidden] = useOnOffState(false);
    const bodyColorStyle = useRef('');

    useEffect(() => {
        bodyColorStyle.current = document.body.style.color;
    }, []);

    const { overlay, showOverlay } = useOverlayMenu();

    const handleShowGeoSwitcherOverlay = () => {
        showOverlay(OverlayTypes.AreaSwitcherOverlay);
        setAreaSwitchVisible();
    };

    const openGeoSwitcher = useHandleOpenGeoSwitcher(handleShowGeoSwitcherOverlay);

    const handleClick = useCallback(() => {
        document.body.style.color = BLOKO_COLOR_GREY_80;
        openGeoSwitcher();

        Analytics.sendHHEventButtonClick('vacancy_search_region');
    }, [openGeoSwitcher]);

    const handleSetOverlayType = (type: OverlayTypes) => {
        showOverlay(type);

        if (type === OverlayTypes.None) {
            document.body.style.color = bodyColorStyle.current;
            setAreaSwitchHidden();
        }
    };

    const handleClose = () => {
        document.body.style.color = bodyColorStyle.current;
        setAreaSwitchHidden();
        showOverlay(OverlayTypes.None);
    };

    return (
        <>
            <NoIndex>
                <Link
                    data-qa="mainmenu_areaSwitcher"
                    appearance={LinkAppearance.Pseudo}
                    title={domainAreaTitle}
                    onClick={handleClick}
                >
                    <span className="supernova-navi-item_area-switcher-button">{domainAreaTitle}</span>
                </Link>
            </NoIndex>
            {!isXs && (
                <div
                    className={classnames('supernova-overlay', {
                        'supernova-overlay_visible': overlay !== OverlayTypes.None,
                    })}
                >
                    <OverlayContent overlayType={overlay} setOverlayType={handleSetOverlayType} />
                </div>
            )}
            {isXs && <AreaSwitcherModal visible={areaSwitchVisible} onClose={handleClose} />}
        </>
    );
};

export default AreaSwitcherPromo;
